import React, { useState } from 'react';
import { gtag, install } from 'ga-gtag';

import { cn } from 'helpers/classnames';
import { guid } from 'helpers/guid';
import LandingPageMedia from 'components/landing/LandingPageMedia';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { formInputsToObject } from 'helpers/HtmlHelpers';
import { AriaLiveTee } from 'components/common/AriaLive';
import ZAWLogo from 'images/zaw/zaw-logo-white.png';
import { trackEvent } from 'helpers/analytics';
import useMartyContext from 'hooks/useMartyContext';
import HtmlToReact from 'components/common/HtmlToReact';
import useEffectOnce from 'hooks/useEffectOnce';
import { postZAWSubmit } from 'apis/zaw';
import { FORM_MESSAGES } from 'constants/zaw';
import { appendUTMs } from 'helpers/zaw';

import css from 'styles/components/landing/zaw/zawForm.scss';

export type IZAWFormStatus = 'amazon' | 'error' | 'success';

export interface IZAWFormProps {
  slotName: string;
  slotIndex: number;
  slotDetails: {
    amazonErrorMessage?: string;
    bannerImgMobileSrc: string;
    bannerImgSrc: string;
    failureMessage?: string;
    heading: string;
    logoImg: string;
    logoSubtext: string;
    noBackgroundImage: boolean;
    replaceLogoText: string;
    successMessage?: string;
    textUnderForm: string;
    underFormContent: string;
    redirectUrl: string;
  };
}

export interface IZAWFormData {
  // pulled from formInputsToObjects types
  company: string | boolean | undefined;
  email: string | boolean | undefined;
  guid: string;
  message: string;
  name: string | boolean | undefined;
  phone: string | boolean | undefined;
  utm_campaign?: string;
  utm_channel?: string;
  utm_content?: string;
  utm_source?: string;
  utm_term?: string;
}

export const ZAWForm = (props: IZAWFormProps) => {
  const { slotName, slotIndex, slotDetails } = props;
  // These slot details are specifically set to match the CMS keys
  const {
    amazonErrorMessage = FORM_MESSAGES.amazon,
    bannerImgMobileSrc,
    bannerImgSrc = 'https://m.media-amazon.com/images/G/01/Zappos/VRSNL-Nov/221107-SNEAKERS-FOR-FALL-HERO-RETINA._CB605761727_.jpg',
    failureMessage = FORM_MESSAGES.error,
    logoImg = ZAWLogo,
    logoSubtext = 'Uniform and Safety workwear programs designed to work for you.',
    noBackgroundImage = false,
    replaceLogoText,
    successMessage = FORM_MESSAGES.success,
    textUnderForm,
    underFormContent,
    redirectUrl
  } = slotDetails;

  const {
    testId,
    marketplace: {
      zaw: {
        google: { id, conversionId }
      }
    }
  } = useMartyContext();

  const contentId = `zaw-form-slot-${slotIndex}`;
  const hasMobileBannerImage = !!bannerImgMobileSrc;

  const [formStatus, setFormStatus] = useState<IZAWFormStatus | undefined>(undefined);

  useEffectOnce(() => {
    install(id);
  });

  const onSubmit = (e: any) => {
    e.preventDefault();

    const companyName = e.target?.elements['company'].value;
    if (companyName?.toLowerCase().includes('amazon')) {
      setFormStatus('amazon');
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const utmParameters = ['utm_source', 'utm_channel', 'utm_campaign', 'utm_term', 'utm_content', 'gclid'];
    const redirectUrlWithUtms = appendUTMs(redirectUrl, urlParams.toString());

    const guidId = guid();

    trackEvent('ZAW_GUID', guidId);
    trackEvent('TE_ZAW_LP2022_FORM_SUBMIT');

    gtag('event', 'conversion', {
      send_to: conversionId
    });

    const { company, email, name, phone } = formInputsToObject(e.target);

    const formData = {
      company,
      phone,
      email,
      name,
      message: '- sent with LP 2022', // TODO remove from customer portal https://code.amazon.com/packages/ZapposAtWorkCustomerPortal/blobs/1df9b556bde7be8208a63bf4e600c16629400610/--/controllers/home.js#L488-L492 then delete
      guid: guidId
    };

    for (const [utm, utmValue] of urlParams) {
      if (utmParameters.includes(utm)) {
        formData[utm as keyof typeof formData] = utmValue;
      }
    }

    postZAWSubmit(formData, setFormStatus, redirectUrlWithUtms);
  };

  return (
    <>
      <article
        className={cn(css.container, 'heroComponent', { [css.noBackgroundImage]: noBackgroundImage })}
        data-slot-id={slotName}
        data-slotindex={slotIndex}
      >
        <div className={cn(css.imageContainer, { [css.noBackgroundImage]: noBackgroundImage })}>
          {!noBackgroundImage && (
            <>
              <div className={cn({ [css.hasMobileImage]: hasMobileBannerImage })}>
                <LandingPageMedia
                  type={'image'}
                  src={bannerImgSrc}
                  isFullWidth={true}
                  slotName={slotName}
                  slotIndex={slotIndex}
                  alt={'Zappos at Work banner image'}
                  autoplay={null}
                  poster={null}
                  srcset={null}
                  embeddedRatio={null}
                  shouldLazyLoad={false}
                  isEmbedded={false}
                  tracks={null}
                  sources={null}
                  loop={null}
                />
              </div>

              {hasMobileBannerImage && (
                <div className={css.mobileImage}>
                  <LandingPageMedia
                    type={'image'}
                    src={bannerImgMobileSrc}
                    isFullWidth={true}
                    slotName={slotName}
                    slotIndex={slotIndex}
                    alt={'Zappos at Work banner image'}
                    autoplay={null}
                    poster={null}
                    srcset={null}
                    embeddedRatio={null}
                    shouldLazyLoad={false}
                    isEmbedded={false}
                    tracks={null}
                    sources={null}
                    loop={null}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className={css.content}>
          <form data-test-id={testId('zaw-form')} className={css.interestFormContainer} onSubmit={onSubmit} id={contentId} method="post">
            {replaceLogoText ? (
              <h3 className={css.heading}>{replaceLogoText}</h3>
            ) : (
              <img className={css.logoImg} alt="Zappos At Work Logo" src={logoImg} />
            )}
            <h1 className={css.logoSubtext}>{logoSubtext}</h1>
            {formStatus === 'success' && (
              <AriaLiveTee>
                <p className={css.success}>{successMessage}</p>
              </AriaLiveTee>
            )}
            {formStatus === 'error' && (
              <AriaLiveTee>
                <p className={css.error}>{failureMessage}</p>
              </AriaLiveTee>
            )}
            {formStatus === 'amazon' && (
              <AriaLiveTee>
                <p className={css.amazon}>{amazonErrorMessage}</p>
              </AriaLiveTee>
            )}
            <fieldset className={css.inputContainer}>
              <input type="text" name="name" placeholder="Name" required />
              <input type="text" name="company" placeholder="Company" required />
              <input className={css.phoneInput} type="tel" name="phone" placeholder="Phone Number" required />
              <input className={css.emailInput} type="email" name="email" placeholder="Email" required />
            </fieldset>
            <button className={css.submitButton} type="submit">
              Submit
            </button>
          </form>
        </div>
      </article>
      {!!underFormContent && <HtmlToReact className={css.zawUnderFormContent}>{underFormContent}</HtmlToReact>}
      {!!textUnderForm && <p className={css.zawTextUnderForm}>{textUnderForm}</p>}
    </>
  );
};

export default withErrorBoundary('ZAWForm', ZAWForm);
