import { useState } from 'react';
import { gtag, install } from 'ga-gtag';

import { cn } from 'helpers/classnames';
import { guid } from 'helpers/guid';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { formInputsToObject } from 'helpers/HtmlHelpers';
import { AriaLiveTee } from 'components/common/AriaLive';
import { trackEvent } from 'helpers/analytics';
import useMartyContext from 'hooks/useMartyContext';
import useEffectOnce from 'hooks/useEffectOnce';
import { postZAWSubmit } from 'apis/zaw';
import { FORM_MESSAGES } from 'constants/zaw';
import { appendUTMs } from 'helpers/zaw';
import { QuickShield } from 'components/icons';

import css from 'styles/components/landing/zaw/zawModernForm.scss';

const contentSymphonyDefaults = {
  heading: 'Get in Touch with a Zappos at Work Team Member',
  quickShieldMessaging: 'Learn about our QuickShield solution for Enterprise clients!'
};

interface IZAWModernFromUtm {
  utm_source?: string;
  utm_channel?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  gclid?: string;
}

type IZAWModernFromCompanySize = string | undefined;

type IZAWModernFromStatus = 'amazon' | 'error' | 'success';
export interface IZAWModernFromProps {
  slotName: string;
  slotIndex: number;
  slotDetails: {
    amazonErrorMessage?: string;
    failureMessage?: string;
    formHeading?: string;
    heading?: string;
    subheading?: string;
    quickShieldMessaging?: string;
    redirectUrl: string;
    bannerImgSrc?: string;
    legalMessaging?: string;
  };
  initialErrorState?: IZAWModernFromStatus;
}

const COMPANY_SIZE_BUTTONS = {
  lessThan200: 'lessThan200',
  moreThan200: 'moreThan200'
};

export const ZAWModernForm = (props: IZAWModernFromProps) => {
  useEffectOnce(() => {
    install(id);
    const urlParams = new URLSearchParams(window.location.search);
    const utmNames: (keyof IZAWModernFromUtm)[] = ['utm_source', 'utm_channel', 'utm_campaign', 'utm_term', 'utm_content', 'gclid'];
    // set utms to pass on submit
    for (const utm of utmNames) {
      if (urlParams && urlParams.has(utm)) {
        const utmValue = urlParams.get(utm);
        updateUtm(prevUtm => ({
          ...prevUtm,
          [utm]: utmValue
        }));
      }
    }
  });

  const { slotName, slotIndex, slotDetails, initialErrorState } = props;
  // These slot details are specifically set to match the CMS keys
  const {
    amazonErrorMessage = FORM_MESSAGES.amazon,
    failureMessage = FORM_MESSAGES.error,
    formHeading = contentSymphonyDefaults.heading,
    heading = 'Safety Footwear & Workwear Programs for Your Team',
    subheading = "Zappos at Work streamlines your teams' safety workwear with a program customized just for your business.",
    quickShieldMessaging = contentSymphonyDefaults.quickShieldMessaging,
    redirectUrl,
    bannerImgSrc = 'https://m.media-amazon.com/images/G/01/ZAW/ZWLNDNGPG/Warehouse.jpg',
    legalMessaging = 'By filling out this form, you agree to receive occasional marketing emails from Zappos at Work about their products, services, events, webinars, or other invitations. You can opt out of these emails at any time by clicking the unsubscribe link at the bottom of each email. Zappos at Work is the provider of this website and respects your privacy rights. To learn more, please read our privacy policy.'
  } = slotDetails;

  const {
    testId,
    marketplace: {
      zaw: {
        google: { id, conversionId }
      }
    }
  } = useMartyContext();

  const contentId = `zaw-form-slot-${slotIndex}`;
  const [formStatus, setFormStatus] = useState<IZAWModernFromStatus | undefined>(initialErrorState);
  const [selected, setSelected] = useState<IZAWModernFromCompanySize>(undefined);
  const [utm, updateUtm] = useState<IZAWModernFromUtm | {}>({});

  const onSubmit = (e: any) => {
    e.preventDefault();

    const companyName = e.target?.elements['company'].value;
    if (companyName?.toLowerCase().includes('amazon')) {
      setFormStatus('amazon');
      return;
    }

    const guidId = guid();
    const { company, email, name, phone, companySize } = formInputsToObject(e.target);
    const redirectUrlWithUtms = appendUTMs(redirectUrl, new URLSearchParams(window.location.search).toString());
    const formData = {
      company,
      companySize,
      email,
      guid: guidId,
      message: '- sent with Modern LP', // TODO remove from customer portal https://code.amazon.com/packages/ZapposAtWorkCustomerPortal/blobs/1df9b556bde7be8208a63bf4e600c16629400610/--/controllers/home.js#L488-L492 then delete
      name,
      phone,
      ...utm
    };

    trackEvent('ZAW_GUID', guidId);
    trackEvent('ZAW_MODERN_FORM_102023');

    gtag('event', 'conversion', {
      send_to: conversionId
    });

    postZAWSubmit(formData, setFormStatus, redirectUrlWithUtms);
  };

  return (
    <article className={css.modernFormWrapper} data-slot-id={slotName} data-slotindex={slotIndex}>
      <div className={css.backDropContainer}>
        <div className={css.headingCalloutWrapper}>
          <h3 className={css.sectionHeading}>{heading}</h3>
          <p className={css.subHeading}>{subheading}</p>
        </div>

        <div className={css.bannerImageContainer}>
          <img className={css.bannerImage} src={bannerImgSrc} alt="Zappos at Work banner" />
        </div>
      </div>

      <div className={css.formContainer}>
        <h3 className={css.formHeading}>{formHeading}</h3>
        <form data-test-id={testId('zaw-form')} className={css.interestFormContainer} onSubmit={onSubmit} id={contentId} method="post">
          {formStatus === 'error' && (
            <AriaLiveTee>
              <p className={css.error}>{failureMessage}</p>
            </AriaLiveTee>
          )}
          {formStatus === 'amazon' && (
            <AriaLiveTee>
              <p className={css.amazon}>{amazonErrorMessage}</p>
            </AriaLiveTee>
          )}

          <div className={css.fields}>
            <fieldset>
              <li className={css.inputWrapper}>
                <label className={css.inputWrapperLabel} htmlFor="name">
                  Name
                </label>
                <input className={css.inputWrapperInput} type="text" name="name" id="name" placeholder="Jane Smith" required />
              </li>
              <li className={css.inputWrapper}>
                <label className={css.inputWrapperLabel} htmlFor="company">
                  Company
                </label>
                <input className={css.inputWrapperInput} type="text" name="company" id="company" placeholder="Where you work!" required />
              </li>
              <li className={css.toggleWrapper}>
                <legend className={css.toggleLabel}>How many employees are eligible for your safety workwear or footwear program?</legend>
                <div className={css.toggleRadioWrapper}>
                  <input
                    className={css.toggleRadioInput}
                    name="companySize"
                    id={COMPANY_SIZE_BUTTONS.lessThan200}
                    type="radio"
                    value={selected === COMPANY_SIZE_BUTTONS.lessThan200 ? selected : undefined}
                    onChange={() => setSelected(COMPANY_SIZE_BUTTONS.lessThan200)}
                    required
                  />
                  <label
                    htmlFor={COMPANY_SIZE_BUTTONS.lessThan200}
                    className={cn(css.toggleRadio, { [css.toggleRadioActive]: selected === COMPANY_SIZE_BUTTONS.lessThan200 })}
                  >
                    Less than 200
                  </label>
                  <input
                    className={css.toggleRadioInput}
                    id={COMPANY_SIZE_BUTTONS.moreThan200}
                    name="companySize"
                    type="radio"
                    value={selected === COMPANY_SIZE_BUTTONS.moreThan200 ? selected : undefined}
                    onChange={() => setSelected(COMPANY_SIZE_BUTTONS.moreThan200)}
                    required
                  />
                  <label
                    htmlFor={COMPANY_SIZE_BUTTONS.moreThan200}
                    className={cn(css.toggleRadio, { [css.toggleRadioActive]: selected === COMPANY_SIZE_BUTTONS.moreThan200 })}
                  >
                    More than 200
                  </label>
                </div>
                <div className={cn(css.quickShield, { [css.quickShieldActive]: selected === COMPANY_SIZE_BUTTONS.moreThan200 })}>
                  <div className={css.quickShieldIcon}>
                    <QuickShield />
                  </div>
                  {quickShieldMessaging}
                </div>
              </li>
              <li className={css.inputWrapper}>
                <label className={css.inputWrapperLabel} htmlFor="email">
                  Email
                </label>
                <input className={css.inputWrapperInput} type="email" name="email" id="email" placeholder="janesmith@example.com" required />
              </li>

              <li className={css.inputWrapper}>
                <label className={css.inputWrapperLabel} htmlFor="phone">
                  Phone Number
                </label>
                <input className={css.inputWrapperInput} type="tel" name="phone" id="phone" placeholder="123 - 456 - 7891" required />
              </li>
            </fieldset>
            <button className={css.submitButton} type="submit">
              Submit
            </button>
            <p className={css.legalMessaging}>{legalMessaging}</p>
          </div>
        </form>
      </div>
    </article>
  );
};

export default withErrorBoundary('ZAWModernForm', ZAWModernForm);
